<template>
  <el-card class="card" :bodyStyle="{ height: '100%' }">
    <div class="cardBody">
      <div
        style="
          display: flex;
          flex-direction: column;
          height: calc(100vh - 180px);
        "
      >
        <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
          <el-table
            :data="SignsList"
            v-loading="tableLoading"
            stripe
            border
            height="100%"
            style="width: 100%"
          >
          <template #empty>
                <p>
                  {{ tableLoading == true ? "数据加载中" : "暂无数据" }}
                </p>
              </template>
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="signName"
              label="签名名称"
             
            >
            </el-table-column>

            <el-table-column
              prop="orderId"
              label="工单号"
              width="300"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="businessType"
              label="适用场景"
              width="300"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="auditStatus"
              label="审核状态"
              width="300"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag v-show="scope.row.auditStatus == 'AUDIT_STATE_INIT'"
                  >审核中</el-tag
                >
                <el-tag
                  v-show="scope.row.auditStatus == 'AUDIT_STATE_PASS'"
                  type="success"
                  >审核通过</el-tag
                >
                <el-tag
                  type="danger"
                  v-show="scope.row.auditStatus == 'AUDIT_STATE_NOT_PASS'"
                  >审核未通过</el-tag
                >
                <el-tag
                  type="info"
                  v-show="scope.row.auditStatus == 'AUDIT_STATE_CANCEL'"
                  >取消审核</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createDate"
              label="创建时间"
              width="300"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>

        <div style="display: flex; justify-content: right">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { querySigns } from "@/api/sms";

export default {
  name: "SmsSignature",
  data() {
    return {
      SignsList: [],
      tableLoading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
 
    };
  },
  created() {
    this.query();
  },
  methods: {
    query() {
      this.tableLoading = true;
      querySigns(this.currentPage, this.pageSize).then((resp) => {
        const content = resp.data.content;
        this.SignsList = content;
        this.total = resp.data.totalElements;
        this.tableLoading = false;
      });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.query();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query();
    },
  },
};
</script>

<style scoped>
.card {
  height: calc(100% - 4px);
}
.cardBody {
  height: calc(100% - 40px);
}
</style>
