import request from "@/utils/request";


// 签名列表
export function querySigns(page, size) {
    return request({
      url: "/sys/aliSms/querySigns?page=" + page + "&size=" + size,
      method: "post",
     
    });
  }

  // 模板列表
export function queryTemplates(page, size) {
    return request({
      url: "/sys/aliSms/queryTemplates?page=" + page + "&size=" + size,
      method: "post",
     
    });
  }
  
    // 编辑模板
export function editTemplate(data) {
  return request({
    url: "/sys/aliSms/editTemplate",
    method: "post",
    data
   
  });
}
    // 新增模板
    export function addTemplate(data) {
      return request({
        url: "/sys/aliSms/addTemplate",
        method: "post",
        data
       
      });
    }

    // 编辑回显
    export function getTemplate(data) {
      return request({
        url: "/sys/aliSms/getTemplate/"+data,
        method: "get",
        data
       
      });
    }
    
    
